@import 'config.scss';

.banner {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 2000;
  transform: translateY(100%);
  perspective: 1000;

  .bannerRichText {
    padding: 10px 40px 10px 25px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: $white;
    background-color: $bay-blue;
    position: relative;
    text-overflow: ellipsis;

    span {
      width: 25px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    p {
      font-size: inherit;
      line-height: inherit;
      color: $white;
      margin: 0;
    }

    a {
      color: $golden-yellow;

      &:hover {
        color: lighten($bay-blue, 30%);
      }
    }

    @include breakpoint($sm) {
      padding: 15px;
      padding-right: 50px;
      font-size: 15px;
      line-height: auto;

      span {
        right: 20px;
      }
    }
  }
}
